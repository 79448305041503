// import "./Footer.css";
// import { Link, NavLink } from "react-router-dom";
// import { BsInstagram, BsLinkedin } from "react-icons/bs";
// import { MdMail } from "react-icons/md";

// const Footer = () => {
//   const date = new Date();
//   const year = date.getFullYear();
//   return (
//     <div className="footer">
//       <div className="flex_row_sb flex_column footer_top">
//         <div className="flex_row_sb">
//           <div className="flex_row footer_top_left">
//             <Link
//               className="icons-a"
//               to="https://instagram.com/fori_inc?igshid=NzZhOTFlYzFmZQ=="
//             >
//               <BsInstagram className="icons-circle" />
//             </Link>
//             <Link
//               className="icons-a"
//               to="https://www.linkedin.com/company/foriinc/"
//             >
//               <BsLinkedin className="icons-circle" />
//             </Link>
//             <Link className="icons-a" to="mailto:ceo@fori.co">
//               <MdMail className="icons-circle" />
//             </Link>
//           </div>
//           <div className="flex_row_sb footer_top_right">
//             <Link to="/policy" className="flex_row linkFooter">
//               Privacy Policy
//             </Link>
//             <Link to="/accessibility " className="flex_row linkFooter">
//               Accessibility
//             </Link>
//           </div>
//         </div>
//         <div className="flex_row linkFooter">
//           <small className="flex_row linkFooter">
//             {year} &copy; Copyrights Fori Inc. All Rights Reserved
//           </small>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Footer;
import React from 'react';
import './Footer.css';
import { HiOutlineShare } from 'react-icons/hi';
import { BsArrowUpRight } from 'react-icons/bs';
// import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
// import { BiWorld } from 'react-icons/bi';
import { Link } from 'react-router-dom';
// import { Link, NavLink } from "react-router-dom";
import { BsInstagram, BsLinkedin } from "react-icons/bs";
import { MdMail } from "react-icons/md";
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

const currentYear = new Date().getFullYear(); // Get the current year dynamically

const Footer = () => {
  return (
    <div className='Footer site_padding'>
      <div>

        <div className='Footer_bottom'>
          <h6 onClick={topFunction}>Go To Top<BsArrowUpRight /></h6>
          <span className='footer_tag'> {currentYear} &copy; Copyrights Fori Inc. All Rights Reserved</span>

          <h6 className='followus'>Connect With Us <HiOutlineShare /></h6>
        </div>
        {/* <div className='policy_links'>
          <h5><Link to="/policy">Privacy Policy</Link></h5>
          <h5><Link to="/accessibility">Accessibility</Link></h5>


        </div> */}
        <div className='followus_links_upper'>
          <div className='flex_row gap1rem followus_links'>
            <h5><Link to="/policy">Privacy Policy</Link></h5>
            <h5><Link to="/accessibility">Accessibility</Link></h5>
          </div>
          <div className='followus_links'>
            {/* 
    <a href='https://instagram.com/fori_inc?igshid=NzZhOTFlYzFmZQ==' target="_blank" rel="noopener noreferrer"><AiFillInstagram /></a>
    <a href='https://www.linkedin.com/company/foriinc/' target="_blank" rel="noopener noreferrer"><AiFillLinkedin /></a>
    <a href='https://fori.co' target="_blank" rel="noopener noreferrer"><BiWorld /></a> 
    */}
            <a
              className="icons-a"
              href="https://instagram.com/fori_inc?igshid=NzZhOTFlYzFmZQ=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsInstagram className="icons-circle" style={{ fontSize: '17px' }} />
            </a>
            <a
              className="icons-a"
              href="https://www.linkedin.com/company/foriinc/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <BsLinkedin className="icons-circle" style={{ fontSize: '17px' }} />
            </a>
            <a
              className="icons-a"
              href="mailto:ceo@fori.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <MdMail className="icons-circle" style={{ fontSize: '20px' }} />
            </a>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Footer;
