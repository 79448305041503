import "./Header.css";
import { Link, NavLink, useNavigate /*useParams*/ } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getUser, logout } from "../../features/AuthSlice";
import fori_logo from "../../Assets/Images/fori Logo.png";
import { useEffect, useState } from "react";
import { setKey, setLanguage, setRegion /*fromAddress*/ } from "react-geocode";
import Select from "react-select";
import HeaderAddressModal from "../HeaderAddressModal/HeaderAddressModal";
import { fromLatLng } from "react-geocode";
import * as opencage from 'opencage-api-client';
import { getAllSkills } from "../../features/AdminSlice";
import { setLocation } from "../../features/locationSlice";
import axios from "axios";
import { text } from "@fortawesome/fontawesome-svg-core";

const Header = () => {
  useEffect(() => {
    // Initialize Geocode library with API key, language, and region
    setKey(process.env.REACT_APP_GOOGLE_API_KEY);
    setLanguage("en");
    setRegion("us");
    dispatch(getUser())
  }, []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.auth);
  const { allSkills } = useSelector((store) => store.admin);
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [addressUnderIcon, setaddressUnderIcon] = useState('');
  const [selectedOption, setSelectedOption] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const storeLocation = useSelector((store) => store.location.location);
  const textualAddress = useSelector((store) => store.location.textual_address);

  // const useLocation = () => {
  //   const location = useSelector((state) => {
  //     if (state.location.location) {
  //       try {
  //         return state.location.location;
  //       } catch (error) {
  //         console.error('Error parsing location:', error);
  //       }
  //     }
  //     return null;
  //   });

  //   return location ? [location.latitude, location.longitude] : [null, null];
  // };




  useEffect(() => {
    if (storeLocation) {
      setLatitude(storeLocation.latitude);
      setLongitude(storeLocation.longitude);
    }
  }, [storeLocation]);

  // console.log(latitude, longitude, "latitude and longitude");
  const pathName = window.location.pathname;
  const handleRegisterClick = () => {
    if (!user) {
      toast("Please sign in to register a worker");
    }
  };



  useEffect(() => {
    dispatch(getAllSkills())
  }, [dispatch]);


  const handleChange = (selectedOption) => {
    if (selectedOption === "") {
      return 1;
    } else {
      navigate(`/skill/${selectedOption}`);
    }
  };



  useEffect(() => {
    const fetchLocation = async () => {
      const apiKey = process.env.REACT_APP_OPENCAGE_API_KEY;
      if (Object.keys(storeLocation).length === 0 && Object.keys(textualAddress).length === 0) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };

            const response1 = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${location.latitude}+${location.longitude}&key=${apiKey}`);
            const address = response1.data.results[0];
            dispatch(setLocation({ location, address }));
          },
            () => {
              // alert("Please enable location services.");
            }
          );
        } else {
          alert("Geolocation is not supported by this browser.");
        }
      }
    };
    fetchLocation();
  }, []);




  const [userLocation, setUserLocation] = useState("");
  const handleLocationChange = (event) => {
    setUserLocation(event.target.value);
  };




  const handleFormSubmit = (event) => {
    event.preventDefault();


    navigate(`/workerPerSkillLocation/${userLocation}`);
  };


  console.log(allSkills, "all skills");
  const selectOptions = allSkills.map(option => ({ value: option.name, label: option.name }));
  const handleAddressModal = () => {
    setIsOpen(!isOpen);
  }

  // Mock fromLatLng to return a promise with predefined result
  // const fromLatLng = () => {
  //   return new Promise((resolve, reject) => {
  //     resolve({
  //       results: [
  //         {
  //           address_components: [
  //             { types: ['locality'], long_name: 'Lahore' },
  //             { types: ['country'], long_name: 'Pakistan' },
  //           ],
  //         },
  //       ],
  //     });
  //   });
  // };


  //Google API
  // const getLocation = () => {
  //   if (latitude && longitude) {
  //     fromLatLng(latitude, longitude)
  //       .then(({ results }) => {
  //         const addressComponents = results[0].address_components;
  //         console.log(results, "results of address components");

  //         let city, country;

  //         for (let i = 0; i < addressComponents.length; i++) {
  //           if (addressComponents[i].types.includes('locality')) {
  //             city = addressComponents[i].long_name;
  //           }
  //           if (addressComponents[i].types.includes('country')) {
  //             country = addressComponents[i].long_name;
  //           }
  //         }

  //         setaddressUnderIcon(`${city}, ${country}`);
  //         // setaddressUnderIcon("Lahore, Pakistan")

  //       })
  //       .catch(error => {
  //         console.error(error);
  //       });

  //   } else {

  //     console.error("Latitude and longitude are not available");
  //   }
  // };


  //OpenCage API key
  const getLocation = () => {
    // if (latitude && longitude) {
    //   opencage.geocode({ key: process.env.REACT_APP_OPENCAGE_API_KEY, q: `${latitude}+${longitude}` })
    //     .then(data => {
    //       const components = data.results[0].components;
    //       const remainingapiCalls = data.rate.remaining;
    //       console.log(data, "results of address components");

    //       let city = components.city || components.town || components.village;
    //       let country = components.country;

    //       setaddressUnderIcon(`${city}, ${country}, ${remainingapiCalls}`);
    //     })
    //     .catch(error => {
    //       console.error(error);
    //     });
    // } else {
    //   console.error("Latitude and longitude are not available");
    // }

    if (textualAddress) {
      const city = textualAddress.components._normalized_city || textualAddress.components.district || textualAddress.components.town;
      const country = textualAddress.components.country;
      setaddressUnderIcon(`${city}, ${country}`);
    }
  };

  useEffect(() => {
    getLocation();
    console.log("running, running");
  }, [latitude, longitude]);

  console.log(latitude, longitude, "latitude and longitude");
  return (
    <div className="Bannerheader">
      <div className="header_left">
        <img src={fori_logo} alt="" />
      </div>
      <div className="header_right flex_row">
        <div>
          <NavLink className={pathName === '/' ? "activeLink" : "non_active"} to="/">HOME</NavLink>
        </div>
        <div>
          <NavLink className={pathName === '/about' ? "activeLink" : "non_active"} to="/about">ABOUT</NavLink>
        </div>
        {user && user.workerId !== null && user.workerId ? (
          <div>
            <NavLink className={pathName === '/profile' ? "activeLink" : "non_active"} to="/profile">MY PROFILE</NavLink>
          </div>
        ) : null}
        {user && user.email === "ceo@fori.co" ? (
          <div>
            <NavLink className={pathName === '/Dashboard' ? "activeLink" : "non_active"} to="/Dashboard">DASHBOARD</NavLink>
          </div>
        ) : null}
        <div>

          {/* <NavLink className={/^\/skills\/\d+$/.test(pathName) ? "activeLink" : "non_active"} to="/skills/1">SKILLS</NavLink> */}
          <NavLink className={pathName.startsWith('/skills') ? "activeLink" : "non_active"} to="/skills">SKILLS</NavLink>
        </div>
        <div className="dropdown">
          <NavLink className="dropbtn">REGISTER</NavLink>
          <div className="dropdown-content">
            <NavLink
              to={user ? "/register-as-worker" : "/signin"}
              onClick={handleRegisterClick}
            >
              BECOME A WORKER
            </NavLink>
            <NavLink
              to={user ? "/addAWorker" : "/signin"}
              onClick={handleRegisterClick}
            >
              ADD A WORKER
            </NavLink>
            <NavLink
              to={user ? "/register-multiple-workers" : "/signin"}
              onClick={handleRegisterClick}
            >
              ADD MULTILPLE WORKERS
            </NavLink>
          </div>
        </div>

        <div className="dropdown">
          <NavLink className="dropbtn">SEARCH</NavLink>
          <div className="dropdown-content search-location">
            <div className="search_Worker_Field">
              <Select
                value={selectedOption}
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                  handleChange(selectedOption.value);
                }}
                options={selectOptions}
                placeholder="Search a Worker"
                styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
              />
            </div>
            {/* <form onSubmit={handleFormSubmit}>
              <input
                className="location-input box_shadow"
                type="search"
                value={userLocation}
                onChange={handleLocationChange}
                title="Enter Your Desired City Name"
                placeholder="Type your location (e.g., Lahore)"
              />
              <button type="submit" style={{ display: "none" }}>
                Submit
              </button>
            </form> */}
          </div>
        </div>
      </div>


      <div className="flex_row_center">
        <div className="location location_heading">
          {/* <FontAwesomeIcon
            icon={faMapMarkerAlt}
            title="Turn on your location"
            onClick={handleLocationClick}
          /> */}
          <button onClick={handleAddressModal}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0,0,256,256">
              <g transform="translate(25.6,25.6) scale(0.8,0.8)"><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{ mixblendmode: "normal" }}><g transform="scale(2,2)"><path d="M64,21c-21.5,0 -38.8,17.3 -39.5,39.4c-0.6,20.7 19.5,48.3 31.6,62.8c2,2.4 4.9,3.7 7.9,3.7c3,0 6,-1.4 7.9,-3.7c12.1,-14.6 32.2,-42.1 31.6,-62.8c-0.7,-22.1 -18,-39.4 -39.5,-39.4z" fill="#12333a"></path><path d="M64,45.9c-8.06336,0 -14.6,6.53664 -14.6,14.6c0,8.06336 6.53664,14.6 14.6,14.6c8.06336,0 14.6,-6.53664 14.6,-14.6c0,-8.06336 -6.53664,-14.6 -14.6,-14.6z" fill="#ffffff"></path><path d="M94,127h-30c-1.7,0 -3,-1.3 -3,-3c0,-1.7 1.3,-3 3,-3h30c1.7,0 3,1.3 3,3c0,1.7 -1.3,3 -3,3z" fill="#12333a"></path><g fill="#12333a"><path d="M111,127c-0.2,0 -0.4,0 -0.6,-0.1c-0.2,0 -0.4,-0.1 -0.6,-0.2c-0.2,-0.1 -0.3,-0.2 -0.5,-0.3c-0.2,-0.1 -0.3,-0.2 -0.5,-0.4c-0.1,-0.1 -0.3,-0.3 -0.4,-0.4c-0.1,-0.2 -0.2,-0.3 -0.3,-0.5c-0.1,-0.2 -0.1,-0.4 -0.2,-0.6c0,-0.2 -0.1,-0.4 -0.1,-0.6c0,-0.2 0,-0.4 0.1,-0.6c0,-0.2 0.1,-0.4 0.2,-0.6c0.1,-0.2 0.2,-0.3 0.3,-0.5c0.1,-0.2 0.2,-0.3 0.4,-0.5c0.2,-0.2 0.3,-0.3 0.5,-0.4c0.2,-0.1 0.3,-0.2 0.5,-0.3c0.2,-0.1 0.4,-0.1 0.6,-0.2c0.4,-0.1 0.8,-0.1 1.2,0c0.2,0 0.4,0.1 0.6,0.2c0.2,0.1 0.4,0.2 0.5,0.3c0.2,0.1 0.3,0.2 0.5,0.4c0.1,0.1 0.3,0.3 0.4,0.5c0.1,0.2 0.2,0.3 0.3,0.5c0.1,0.2 0.1,0.4 0.2,0.6c0,0.2 0.1,0.4 0.1,0.6c0,0.2 0,0.4 -0.1,0.6c0,0.2 -0.1,0.4 -0.2,0.6c-0.1,0.2 -0.2,0.4 -0.3,0.5c-0.1,0.2 -0.2,0.3 -0.4,0.4c-0.1,0.1 -0.3,0.3 -0.5,0.4c-0.2,0.1 -0.3,0.2 -0.5,0.3c-0.2,0.1 -0.4,0.1 -0.6,0.2c-0.2,0.1 -0.4,0.1 -0.6,0.1z"></path></g></g></g></g>
            </svg>

          </button>
          <h6 className="addressUnderIcon">{addressUnderIcon === null ? 'Loading...' : addressUnderIcon}</h6>

          {isOpen && <HeaderAddressModal open={isOpen} onClose={handleAddressModal} address={address} setAddress={setAddress} />}
          {/* <HeaderAddressModal  /> */}


        </div>

        {user ? (
          <>
            <button
              className="btn"
              onClick={() => {
                localStorage.removeItem('becomeAWorkerData');
                localStorage.removeItem('addAWorkerData');
                // localStorage.removeItem("location");
                dispatch(logout()).then(() => navigate("/signin"));
              }}
            >
              Logout
            </button>
          </>
        ) : (
          <Link className="btn " to="/signin">
            SIGN IN
          </Link>
        )}
      </div>

    </div>
  );
};

export default Header;
