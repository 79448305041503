import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_ENCRYPT_AND_DECRYPT_SECRET_KEY;
// Encryption function
export const encryptAndStore = (data, key) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_ENCRYPT_AND_DECRYPT_SECRET_KEY);
  localStorage.setItem(key, encryptedData.toString());
  return encryptedData.toString();
}

// Decryption function
export const decryptAndRetrieve = (key) => {
  const encryptedData = localStorage.getItem(key)
  if (encryptedData) {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData.toString(), process.env.REACT_APP_ENCRYPT_AND_DECRYPT_SECRET_KEY);
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  }
  return null;
}
