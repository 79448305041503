import React, { useEffect, useState } from "react";
import "./MobileNav.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
// import Select, { components } from "react-select";
import { jobTitles } from "../../Assets/Data/data";
import Select from "react-select";
import { getAllSkills } from "../../features/AdminSlice";



const MobileNav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPage = window.location.pathname;

  const { user } = useSelector((store) => store.auth);
  const { allSkills } = useSelector((store) => store.admin);
  const [show, setShow] = useState(false);
  const [firstShow, setFirstShow] = useState(true);


  const handleRegisterClick = (e) => {
    showFunction();
    if (!user) {
      e.preventDefault();
      // navigate("/signin");
      toast("Please sign in to add a worker");
    }
  };

  const showFunction = () => {
    setFirstShow(false);
    setShow(!show);
  };



  useEffect(() => {
    dispatch(getAllSkills())
  }, [dispatch]);



  const options = allSkills.map((title) => ({ value: title.name, label: title.name }));
  const selectOptions = options.map(option => ({ value: option.value, label: option.label }));
  const handleChange = (selectedOption) => {
    navigate(`/skill/${selectedOption}`);
    showFunction();
  };
  // const [userLocation, setUserLocation] = useState("");

  // const handleLocationChange = async (event) => {
  //   const newLocation = event.target.value;
  //   setUserLocation(newLocation);
  //   localStorage.setItem("userAddress", newLocation);
  // };
  // const handleFormSubmit = (event) => {
  //   event.preventDefault(); // Prevent the form from refreshing the page

  //   // Navigate to the new page with the user's location
  //   navigate(`/workerPerSkillLocation/${userLocation}`);
  //   showFunction();
  // };
  return (
    <div className="Mob_Navbar">
      <div className={show ? "Mob_Navbar_right" : firstShow === false && show === false ? "hidden_animation" : "hidee"}
      >
        <div className="select-input2">
          <Select
            value={selectOptions.find(option => option.value === jobTitles.value)}
            onChange={(selectedOption) => handleChange(selectedOption.value)}
            options={selectOptions}
            placeholder="Search a Worker"
            styles={{
              container: (provided) => ({ ...provided, width: '100%' }),
              option: (provided) => ({ ...provided, fontSize: window.innerWidth <= 768 ? '14px' : '16px', letterSpacing: 'normal', }),
              placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
              input: (provided) => ({ ...provided, fontSize: '14px' }),
            }}
          />
        </div>

        {/* <form onSubmit={handleFormSubmit}>
          <input
            className="location-input2 box_shadow"
            type="search"
            value={userLocation}
            onChange={handleLocationChange}
            title="Enter Your Desired City Name"
            placeholder="Type your location (e.g., Lahore)"
          />
          <button type="submit" style={{ display: "none" }}>
            Submit
          </button>
        </form> */}
        <NavLink onClick={showFunction} to="/">
          HOME
        </NavLink>
        <NavLink onClick={showFunction} to="/about">
          ABOUT
        </NavLink>
        {user && user.workerId && user.workerId !== null ? (
          <NavLink onClick={showFunction} to="/profile">
            PROFILE
          </NavLink>
        ) : null}
        {user && user.email === "ceo@fori.co" ? (
          <div>
            <NavLink onClick={showFunction} to="/Dashboard">DASHBOARD</NavLink>
          </div>
        ) : null}
        <NavLink onClick={showFunction} to="/skills?page=1">
          SKILLS
        </NavLink>
        <NavLink
          to={user ? "/register-as-worker" : "/signin"}
          onClick={handleRegisterClick}
        >
          BECOME A WORKER
        </NavLink>
        <NavLink
          to={user ? "/addAWorker" : "/signin"}
          onClick={handleRegisterClick}
        >
          ADD A WORKER
        </NavLink>
        <NavLink
          to={user ? "/register-multiple-workers" : "/signin"}
          onClick={handleRegisterClick}
        >
          ADD MULTIPLE WORKERS
        </NavLink>
      </div>
      {currentPage && (
        <div
          className={show ? "cross burger" : "burger"}
          onClick={showFunction}
        >
          <div></div>
          <div></div>
        </div>
      )}
    </div>
  );
};

export default MobileNav;
