// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notAuthorizedIcon {
    font-size: xx-large;
}

.register_worker1 {
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 5rem 4rem;
    min-height: 90vh;
}`, "",{"version":3,"sources":["webpack://./src/Pages/NotAuthorized/NotAuthorized.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,SAAS;IACT,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".notAuthorizedIcon {\n    font-size: xx-large;\n}\n\n.register_worker1 {\n    justify-content: center;\n    align-items: center;\n    gap: 2rem;\n    padding: 5rem 4rem;\n    min-height: 90vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
