import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";



const initialState = {
    tracking: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};


export const trackingForiUser = createAsyncThunk(
    "Worker/TrackingFori",
    async (id, thunkAPI) => {
        try {
            const API_URL = `${process.env.REACT_APP_TRACKING_FORI_SERVER_URL}/auth/getUserJourney/${id}`;
            const response = await axios.get(API_URL);
            return response.data;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.response.data.status ||
                error.message ||
                error.toString();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const TrackingForiSlice = createSlice({
    name: "TrackingFori",
    initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isLoading = false;
        },
    },
    extraReducers: {
        [trackingForiUser.pending]: (state) => {
            state.isLoading = true;
        },
        [trackingForiUser.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.tracking = payload;
        },
        [trackingForiUser.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.message = payload;
        },
    },
});

export const { reset } = TrackingForiSlice.actions;
export default TrackingForiSlice.reducer;