import { configureStore } from '@reduxjs/toolkit';
import workerSliceReducer from '../features/WorkerRegistrationSlice';
import authSliceReducer from '../features/AuthSlice';
import adminReducer from '../features/AdminSlice';
import { locationReducer } from '../features/locationSlice';
import trackingReducer from '../features/TrackingFori';


export const store = configureStore({
  reducer: {
    worker: workerSliceReducer,
    auth: authSliceReducer,
    location: locationReducer,
    admin: adminReducer,
    tracking: trackingReducer,
  },
});