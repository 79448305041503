import React from "react";
import { MdDoNotDisturb } from 'react-icons/md'
import './NotAuthorized.css'



const NotAuthorized = () => {

    return (
        <div className="flex_column register_worker1">
            <div className="notAuthorizedIcon">
                <MdDoNotDisturb />
            </div>
            <div className="flex_column register_worker_form">
                <h2>Not Authorized</h2>
                <p>
                    You are not authorized to view this page.
                </p>
            </div>
        </div>
    );
};

export default NotAuthorized;
