import React, { useState } from 'react';
import '../SignIn/SignIn.css'
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { recommendSkill } from '../../features/WorkerRegistrationSlice';
import NotAuthorized from '../NotAuthorized/NotAuthorized';
import { MdInfoOutline } from 'react-icons/md';


const RecommendSkill = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((store) => store.auth);

    const [skillName, setSkillName] = useState('')
    const [showDescription, setShowDescription] = useState(true);


    const onChange = (e) => {
        setSkillName(e.target.value);
    };


    const toggleDescription = () => {
        setShowDescription(showDescription);
    }




    const onSubmit = async (e) => {
        e.preventDefault();
        if (skillName === '') {
            toast('Please provide skill name');
        } else if (skillName !== '') {
            setLoading(true);
            dispatch(recommendSkill({ skillName }))
                .then((result) => {
                    setLoading(false);
                    if (result.payload.success) {
                        navigate('/skills');
                    }
                })
                .catch((error) => {
                    toast.error('Error in recommending a new skill: ' + error.message);
                });
        } else {
            toast('Please provide complete details')
        }
    };




    return (
        <>
            {user ? (
                <div className='container_signin'>
                    <div className='container_signin_left'>
                        <h4 className='headingTag'>Greetings!</h4>
                        <h5>We are offering you the chance to broaden the existing skill set. Please suggest a new skill.</h5>
                    </div>
                    <div className='container_signin_right'>
                        <form className='signin_form'>
                            <div className='signin_form_div'>
                                <div className='form_input_signin'>
                                    <label className="required-field">Skill Name <span>*</span>
                                        <div className="info-container">
                                            <button className='iBtn' type='button' onClick={toggleDescription}>
                                                <MdInfoOutline />
                                            </button>
                                            <div className={`iBtn-description ${showDescription ? 'show' : ''}`}>
                                                <p>
                                                    The skill must not already exist, and it should contain only letters from A to Z. It should not include any numbers or special characters like $#@&*. Additionally, you can recommend the same skill only once.
                                                </p>
                                            </div>
                                        </div>
                                    </label>
                                    <input type='text' id='skillName' name='skillName' value={skillName} placeholder='Enter a skill name' onChange={onChange} />
                                </div>
                            </div >
                            <div className='btnDiv'>
                                <button type='submit' onClick={onSubmit} className='btn'>
                                    {loading ? 'Loading...' : 'Submit'}
                                </button>
                            </div>
                        </form >

                    </div>
                </div >) : <NotAuthorized />
            }
        </>

    );
};

export default RecommendSkill;
