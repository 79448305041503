export const jobTitles = [
  "Accountancy & Entrepreneurship",
  "Accountant",
  "Actor",
  "Architect",
  "Artist",
  "Auto & Farm",
  "Auto Cad",
  "Auto Mechanic",
  "Baker",
  "Basic Housekeeping",
  "Beautician",
  "Boiler Operator",
  "Butcher",
  "Carpenter",
  "Caterer",
  "Civil Drafting",
  "Computer Operator",
  "Contractor",
  "Cook",
  "Craftsman",
  "Customer Service",
  "Delivery Boy",
  "Digger",
  "Driver",
  "Electrician",
  "Fabric Painting",
  "Fabrication",
  "Fashion Designer",
  "Game Developer",
  "Gardener",
  "Generator Mechanic",
  "Graphic Designer",
  "Grinder",
  "Guard",
  "Hand Embriodery",
  "Helper",
  "Horse Trainer",
  "Imam",
  "Industrial Designer",
  "Instructor",
  "Interior Designer",
  "Jeweler",
  "Labourer",
  "Lawyer",
  "Machine Embriodery",
  "Maid",
  "Mason",
  "Mechanic",
  "Mistri",
  "Mobile Phone Repairing",
  "Mobile Repair",
  "Model",
  "Motivational Speaker",
  "Nutritionist",
  "Office Boy",
  "Painter",
  "Photographer",
  "Plasterer",
  "Plumber",
  "Printing Press",
  "Programmer",
  "Receptionist",
  "Salesman",
  "Servant",
  "Shutterer",
  "Singer",
  "Steel Fixer",
  "Supervisor",
  "Surveyor",
  "Tailor",
  "Taxi",
  "Teacher",
  "Thread Winding",
  "Tiler",
  "Trainer",
  "Translator",
  "Tutor",
  "Ups Repair",
  "Waiter",
  "Weaving Techniques",
  "Welder",
  "Wood Work",
  "Writer"
];