import "./Header.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-hot-toast";
import { getUser, logout } from "../../features/AuthSlice";
import fori_logo from "../../Assets/Images/fori Logo.png";
import { useCallback, useEffect, useRef, useState } from "react";
import { User } from 'lucide-react';
import { setKey, setLanguage, setRegion } from "react-geocode";
import Select from "react-select";
import HeaderAddressModal from "../HeaderAddressModal/HeaderAddressModal";
import { Bell } from 'lucide-react';
import { getAllSkills } from "../../features/AdminSlice";
import { setLocation } from "../../features/locationSlice";
import axios from "axios";
import { otherIcons } from "../../Assets/Data/otherIcons";
import { getBookingsByWorkerID } from "../../features/Booking";


const Header = () => {
  useEffect(() => {
    // Initialize Geocode library with API key, language, and region
    setKey(process.env.REACT_APP_GOOGLE_API_KEY);
    setLanguage("en");
    setRegion("us");
    dispatch(getUser())
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((store) => store.auth);
  const { allSkills } = useSelector((store) => store.admin);
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [addressUnderIcon, setaddressUnderIcon] = useState('');
  const [selectedOption, setSelectedOption] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const storeLocation = useSelector((store) => store.location.location);
  const textualAddress = useSelector((store) => store.location.textual_address);
  const { workerBookings } = useSelector((store) => store.booking);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const notificationRef = useRef(null);

  console.log("workerBookings", workerBookings);

  const fetchBookings = useCallback(() => {
    if (user && user.workerId) {
      dispatch(getBookingsByWorkerID(user.workerId));
    }
  }, [dispatch, user]);

  useEffect(() => {
    fetchBookings();
  }, [fetchBookings]);

  useEffect(() => {
    if (user && user.workerId && workerBookings && workerBookings.bookings) {
      const now = new Date();
      const sevenDaysAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

      const pendingBookings = workerBookings.bookings.filter(
        booking => booking.jobStatus === "pending" && new Date(booking.bookingCreatedAt) > sevenDaysAgo
      );

      const newNotifications = pendingBookings.map(booking => ({
        id: booking._id,
        message: `New booking request for ${booking.jobName}`,
        createdAt: new Date(booking.bookingCreatedAt)
      }));


      newNotifications.sort((a, b) => b.createdAt - a.createdAt);

      setNotifications(newNotifications);
    } else {
      setNotifications([]);
    }
  }, [user, workerBookings]);

  const toggleNotifications = () => setShowNotifications(!showNotifications);

  const getTimeAgo = (date) => {
    const now = new Date();
    const diffInMinutes = Math.floor((now - date) / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
    } else if (diffInMinutes < 24 * 60) {
      const diffInHours = Math.floor(diffInMinutes / 60);
      return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    } else {
      const diffInDays = Math.floor(diffInMinutes / (24 * 60));
      return `${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
    }
  };



  useEffect(() => {
    if (storeLocation) {
      setLatitude(storeLocation.latitude);
      setLongitude(storeLocation.longitude);
    }
  }, [storeLocation]);

  const pathName = window.location.pathname;
  const handleRegisterClick = () => {
    if (!user) {
      toast("Please sign in to register a worker");
    }
  };



  useEffect(() => {
    dispatch(getAllSkills())
  }, [dispatch]);


  const handleChange = (selectedOption) => {
    if (selectedOption === "") {
      return 1;
    } else {
      navigate(`/skill/${selectedOption}`);
    }
  };
  const [issOpen, setIssOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIssOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIssOpen(!isOpen);


  useEffect(() => {
    const fetchLocation = async () => {
      const apiKey = process.env.REACT_APP_OPENCAGE_API_KEY;
      if (Object.keys(storeLocation).length === 0 && Object.keys(textualAddress).length === 0) {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(async (position) => {
            const location = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            };

            const response1 = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${location.latitude}+${location.longitude}&key=${apiKey}`);
            const address = response1.data.results[0];
            dispatch(setLocation({ location, address }));
          },
            () => {
              // alert("Please enable location services.");
            }
          );
        } else {
          alert("Geolocation is not supported by this browser.");
        }
      }
    };
    fetchLocation();
  }, []);






  const selectOptions = allSkills.map(option => ({ value: option.name, label: option.name }));
  const handleAddressModal = () => {
    setIsOpen(!isOpen);
  }




  //OpenCage API key
  const getLocation = () => {


    if (textualAddress) {
      const city = textualAddress.components._normalized_city || textualAddress.components.district || textualAddress.components.town;
      const country = textualAddress.components.country;
      setaddressUnderIcon(`${city}, ${country}`);
    }
  };

  useEffect(() => {
    getLocation();
    console.log("running, running");
  }, [latitude, longitude]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications)
    navigate('/bookings/availableBookings');
  };


  return (
    <div className="Bannerheader">
      <div className="header_left">
        <Link to="/">
          <img src={fori_logo} alt="Fori Logo" />
        </Link>

      </div>
      <div className="header_right flex_row">
        <div>
          <NavLink className={pathName === '/' ? "activeLink" : "non_active"} to="/">HOME</NavLink>
        </div>
        <div>
          <NavLink className={pathName === '/about' ? "activeLink" : "non_active"} to="/about">ABOUT</NavLink>
        </div>
        {user &&

          <div>
            <NavLink className={pathName === '/Bookings' ? "activeLink" : "non_active"} to="/Bookings">DASHBOARD</NavLink>
          </div>
        }
        {user && <div>
          <NavLink className={pathName === '/Bookings/createBooking' ? "activeLink" : "non_active"} to="/Bookings/createBooking">BOOK WORKER</NavLink>
        </div>}
        {user && <div>
          <NavLink className={pathName === '/Bookings/createBroadcastJob' ? "activeLink" : "non_active"} to="/Bookings/createBroadcastJob">BROADCAST JOB</NavLink>
        </div>}

        {user && user.email === "ceo@fori.co" ? (
          <div>
            <NavLink className={pathName === '/Dashboard' ? "activeLink" : "non_active"} to="/Dashboard">DASHBOARD</NavLink>
          </div>
        ) : null}
        <div>
          <NavLink className={pathName.startsWith('/skills') ? "activeLink" : "non_active"} to="/skills">SKILLS</NavLink>
        </div>
        <div className="dropdown">
          <NavLink className="dropbtn">REGISTER</NavLink>
          <div className="dropdown-content">
            <NavLink
              to={user ? "/register-as-worker" : "/signin"}
              onClick={handleRegisterClick}
            >
              BECOME A WORKER
            </NavLink>
            <NavLink
              to={user ? "/addAWorker" : "/signin"}
              onClick={handleRegisterClick}
            >
              ADD A WORKER
            </NavLink>
            <NavLink
              to={user ? "/register-multiple-workers" : "/signin"}
              onClick={handleRegisterClick}
            >
              ADD MULTILPLE WORKERS
            </NavLink>
          </div>
        </div>

        <div className="dropdown">
          <NavLink className="dropbtn">SEARCH</NavLink>
          <div className="dropdown-content search-location">
            <div className="search_Worker_Field">
              <Select
                value={selectedOption}
                onChange={(selectedOption) => {
                  setSelectedOption(selectedOption);
                  handleChange(selectedOption.value);
                }}
                options={selectOptions}
                placeholder="Search a Worker"
                styles={{ container: (provided) => ({ ...provided, width: '100%' }) }}
              />
            </div>
          </div>
        </div>
      </div>


      <div className="flex_row_center">
        {user && (
          <div className="notification-container" ref={notificationRef}>
            <button className="notification" onClick={toggleNotifications}>
              <Bell />
              {notifications.length > 0 && <span className="notification-badge">{notifications.length}</span>}
            </button>
            {showNotifications && (
              <div className="notification-panel box_shadow" style={{ padding: "0" }}>
                <h4>New Booking Requests</h4>
                {notifications.length > 0 ? (
                  <ul>
                    {notifications.map((notif) => (
                      <li key={notif.id} onClick={handleNotificationClick} className="notifications">
                        {notif.message}
                        <br />
                        <small>{getTimeAgo(notif.createdAt)}</small>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p style={{ padding: "4px" }}>No new booking requests at the moment. Check back later!</p>
                )}
              </div>
            )}
          </div>
        )}
        <div className="location location_heading">

          <button onClick={handleAddressModal}>
            {otherIcons.locationIcon}

          </button>
          <h6 className="addressUnderIcon">{addressUnderIcon === null ? 'Loading...' : addressUnderIcon}</h6>

          {isOpen && <HeaderAddressModal open={isOpen} onClose={handleAddressModal} address={address} setAddress={setAddress} />}



        </div>

        {user ? (
          <>
            <div className="avatar-container" ref={dropdownRef} >
              <button
                onClick={toggleDropdown}
                className="avatar-button bold"
              >
                {user != null ? (user.name ? user.name.charAt(0).toUpperCase() : '') : ''}

              </button>
              {issOpen && (
                <div className="dropdownn box_shadow ">
                  <div className="user-info">
                    <p className="user-name">Name: {user.name}</p>
                    <p className="user-role">Role: {user.workerId != null ? "User, Worker" : "User"}</p>
                  </div>
                  <Link to="/Bookings/allPaymentMethods" className="dropdown-item" onClick={() => setIsOpen(false)}>
                    Saved Payment Methods
                  </Link>
                  <hr />
                  <button
                    className="logOut_btn"
                    onClick={() => {
                      localStorage.removeItem('becomeAWorkerData');
                      localStorage.removeItem('addAWorkerData');

                      dispatch(logout()).then(() => navigate("/signin"));
                      setIssOpen(false);
                    }}
                  >
                    {otherIcons.logOut} Logout
                  </button>
                </div>
              )}
            </div>
          </>
        ) : (
          <Link className="btn " to="/signin">
            SIGN IN
          </Link>
        )}
      </div>

    </div>
  );
};

export default Header;
