import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { UserCheck, Briefcase, Megaphone, CreditCard, User, Menu as MenuIcon, ChevronRight, ChevronDown, CheckSquare, Share2, Clock } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { getEmployerBroadcastJob } from '../../features/Booking';

import "../Dashboard/Dashboard.css";
const Sidebar = ({ isSidebarCollapsed, toggleNewSection, isNewSectionExpanded, isActive, quickStats }) => {
   const dispatch = useDispatch();
   const user = useSelector((store) => store.auth.user);
   const { employerBroadcastJob } = useSelector((store) => store.booking);
   useEffect(() => {
      dispatch(getEmployerBroadcastJob());
   }, [dispatch]);
   console.log(employerBroadcastJob, "employerBroadcastJob");




   if (!user) return null;

   return (
      <div className={`sidebar ${isSidebarCollapsed ? 'hidden' : 'visible'}`}>
         <nav>
            <ul>
               {user.workerId && (
                  <li className={isActive('profile') ? 'active' : ''}>
                     <Link to="/Bookings/profile">
                        <User size={18} />
                        <strong>Profile</strong>
                     </Link>
                  </li>
               )}

               <li className="collapsible-section">
                  <div className="section-header" onClick={toggleNewSection}>
                     {isNewSectionExpanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
                     <strong>Bookings</strong>
                  </div>
                  {isNewSectionExpanded && (
                     <ul className="subsection-list">
                        <li className={isActive('bookingsAsEmployer') ? 'active' : ''}>
                           <Link to="/Bookings/bookingsAsEmployer">
                              <Briefcase size={18} />
                              <strong>Manage Your Jobs</strong>
                           </Link>
                        </li>
                        {user.workerId && (
                           <li className={isActive('availableBookings') ? 'active' : ''}>
                              <Link to="/Bookings/availableBookings">
                                 <UserCheck size={18} />
                                 <strong>Find Work</strong>
                              </Link>
                           </li>
                        )}
                     </ul>
                  )}
               </li>

               {user.workerId && (
                  <li className={isActive('broadcastJob') ? 'active' : ''}>
                     <Link to="/Bookings/broadcastJob">
                        <Megaphone size={18} />
                        <strong>BroadCast Job</strong>
                     </Link>
                  </li>
               )}

               <li className={isActive('createBooking') ? 'active' : ''}>
                  <Link to="/Bookings/createBooking">
                     <CheckSquare size={18} />
                     <strong>Book a Worker</strong>
                  </Link>
               </li>
               <li className={isActive('createBroadcastJob') ? 'active' : ''}>
                  <Link to="/Bookings/createBroadcastJob">
                     <Share2 size={18} />
                     <strong>Broadcast your Job</strong>
                  </Link>
               </li>
               <li className={isActive('getBroadcastJobs') ? 'active' : ''}>
                  <Link to="/Bookings/getBroadcastJobs">
                     <Clock size={18} />
                     <strong>Pending Jobs</strong>
                  </Link>
               </li>
               {employerBroadcastJob?.broadcastJobs?.length > 0 && (
                  <li className={isActive('manageApplicants') ? 'active' : ''}>
                     <Link to="/Bookings/manageApplicants">
                        <UserCheck size={18} />
                        <strong>Manage Applicants</strong>
                     </Link>
                  </li>)}

               <li className={isActive('allPaymentMethods') ? 'active' : ''}>
                  <Link to="/Bookings/allPaymentMethods">
                     <CreditCard size={18} />
                     <strong>Saved Payment Method</strong>
                  </Link>
               </li>
            </ul>
         </nav>
      </div>
   );
};

const Bookings = () => {
   const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
   const [isNewSectionExpanded, setIsNewSectionExpanded] = useState(false);
   const location = useLocation();
   const { workerBookings } = useSelector((store) => store.booking);

   const calculateQuickStats = () => {
      if (!workerBookings || !workerBookings.bookings) {
         return {
            activeJobs: 0,
            completedThisWeek: 0,
            earningsThisMonth: 0,
            currentRating: 0
         };
      }

      const now = new Date();
      const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);

      const activeJobs = workerBookings.bookings.filter(booking =>
         booking.jobStatus === 'pending' || booking.jobStatus === 'accepted'
      ).length;

      const completedThisWeek = workerBookings.bookings.filter(booking =>
         booking.jobStatus === 'completed' &&
         new Date(booking.bookingCompletedAt) > oneWeekAgo
      ).length;

      const earningsThisMonth = workerBookings.bookings.filter(booking =>
         booking.jobStatus === 'completed' &&
         new Date(booking.bookingCompletedAt).getMonth() === now.getMonth()
      )
         .reduce((sum, booking) => sum + (parseFloat(booking.jobAmount) * (booking.workerRate / 100)), 0);

      const ratings = workerBookings.bookings.filter(booking => booking.jobStatus === 'completed' && booking.workerRating)
         .map(booking => booking.workerRating);
      const currentRating = ratings.length > 0 ?
         ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length :
         0;

      return {
         activeJobs,
         completedThisWeek,
         earningsThisMonth,
         currentRating
      };
   };

   const quickStats = calculateQuickStats();

   const checkAndCollapseSidebar = () => {
      setIsSidebarCollapsed(window.innerWidth < 768);
   };

   useEffect(() => {
      checkAndCollapseSidebar();
      window.addEventListener('resize', checkAndCollapseSidebar);
      return () => window.removeEventListener('resize', checkAndCollapseSidebar);
   }, []);

   const isActive = (path) => location.pathname.includes(path);

   const toggleNewSection = () => setIsNewSectionExpanded(!isNewSectionExpanded);

   return (
      <div className="dashboard_container">
         <Sidebar
            isSidebarCollapsed={isSidebarCollapsed}
            toggleNewSection={toggleNewSection}
            isNewSectionExpanded={isNewSectionExpanded}
            isActive={isActive}
            quickStats={quickStats}
         />
         <div className="main_content_sidebar">
            <button className="toggle_sidebar_button" onClick={() => setIsSidebarCollapsed(!isSidebarCollapsed)}>
               <MenuIcon size={24} />
            </button>
            <div className="main-content-wrapper">
               <Outlet />
            </div>
         </div>
      </div>
   );
};

export default Bookings;